import PropTypes from "prop-types"
import React from "react"

const Lazy = ({ children }) => {
  const ref = React.useRef(null)
  const observer = React.useRef(null)
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    if (observer.current) {
      return
    }
    observer.current = new window.IntersectionObserver(
      ([entry]) => {
        if (!loaded && entry.intersectionRatio > 0) {
          setLoaded(true)
        }
      },
      {
        root: null,
        rootMargin: "50px",
        threshold: [0.33, 0.66, 1.0],
      }
    )
  }, [loaded])

  React.useEffect(() => {
    const { current: currentObserver } = observer
    if (currentObserver) {
      currentObserver.disconnect()
      currentObserver.observe(ref.current)
    }

    return () => {
      if (currentObserver) {
        currentObserver.disconnect()
      }
    }
  }, [ref])

  return (
    <div ref={ref} className="w-full h-full">
      {loaded ? children : null}
    </div>
  )
}

Lazy.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Lazy
