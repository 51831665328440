import PropTypes from "prop-types"
import React from "react"

import { getResponsiveSource } from "../lib/vimeo"

const VideoThumbnail = ({ video, alt }) => {
  const sources = video.content && video.content.images

  const ref = React.useCallback(
    node => {
      if (node && sources) {
        const { height, width } = node.getBoundingClientRect()
        const source = getResponsiveSource({
          sources,
          width,
          height,
        })
        if (source) {
          node.src = source.url
        }
      }
    },
    [sources]
  )

  return (
    <img ref={ref} className="block object-cover w-full h-full" alt={alt} />
  )
}

VideoThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
  alt: PropTypes.string,
}

VideoThumbnail.defaultProps = {
  alt: "",
}

export default VideoThumbnail
