import PropTypes from "prop-types"
import React from "react"

const PoetryBar = ({ lines }) => (
  <div className="w-full h-full bg-black text-pink font-bold flex items-center lfo-group">
    <p className="whitespace-no-wrap w-auto inline-block lfo-marquee lfo-group-hover:animation-pause lfo-pl-full">
      {lines.map((item, index) => (
        <span key={index} className="mr-8">
          {item}
        </span>
      ))}
    </p>
  </div>
)

PoetryBar.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.string),
}

export default PoetryBar
