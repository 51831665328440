export const getResponsiveSource = ({
  sources = [],
  width = 0,
  height = 0,
}) => {
  if (sources.length === 0 || width === 0 || height === 0) {
    return null
  }
  // set a minimum width and height because the smallest thumbnail sizes on vimeo are letterboxed
  const minWidth = width ? Math.max(width, 240) : 0
  const minHeight = height ? Math.max(height, 160) : 0

  const source = sources
    // filter hls source
    .filter(item => item.width && item.height)
    // sort by highest width first
    .sort((a, b) => (a.width > b.width ? -1 : 1))
    // find smallest source that will fit the dimensions
    .reduce((acc, cur) =>
      !acc || (cur.width >= minWidth && cur.height >= minHeight) ? cur : acc
    )

  return source
}
