import Link from "next/link"
import PropTypes from "prop-types"
import React from "react"

import { useAppContext } from "../components/app-context"
import Layout from "../components/layout"
import Lazy from "../components/lazy"
import PoetryBar from "../components/PoetryBar"
import SEO from "../components/seo"
import Video from "../components/video"
import VideoThumbnail from "../components/video-thumbnail"
import { getItem, setItem } from "../lib/cache"
import Storyblok, { getStoryblokParams } from "../lib/storyblok"

const key = "home"

const Home = ({ story }) => {
  const { isMobileDevice } = useAppContext()

  setItem(key, story)

  return (
    <Layout>
      <SEO
        path="/"
        image={
          story.content.video &&
          story.content.video.content &&
          story.content.video.content.images[
            story.content.video.content.images.length - 1
          ].url
        }
      />
      <main>
        <div className="w-full md:w-1/3 bg-gray-200 relative lfo-group">
          <h2 className="fixed px-4 md:px-10 py-6 z-10 text-xl leading-tight lfo-group-hover:opacity-0">
            collections
          </h2>
          <ul>
            {story.content.collections.map(item => (
              <li key={item.slug}>
                <article className="relative aspect-ratio-16/9">
                  <Link
                    href="/collections/[slug]"
                    as={`/collections/${item.slug}`}
                  >
                    <a className="group block w-full h-full focus:outline-none">
                      <div className="absolute inset-0 z-10 flex flex-col justify-center lfo-bg-black-5 px-6 md:px-10 text-white opacity-0 group-hover:opacity-100 focus:opacity-100">
                        <h2 className="text-2xl leading-none mb-2">{`${item.name} — collection`}</h2>
                        {item.content.subtitle && (
                          <p className="text-base leading-tight">
                            {item.content.subtitle}
                          </p>
                        )}
                      </div>
                      <div className="absolute inset-0">
                        <Lazy>
                          {isMobileDevice ? (
                            <VideoThumbnail
                              video={item.content.preview || item.content.video}
                            />
                          ) : (
                            <Video
                              video={item.content.preview || item.content.video}
                              autoPlay
                              muted
                              loop
                            />
                          )}
                        </Lazy>
                      </div>
                    </a>
                  </Link>
                </article>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="hidden md:block fixed inset-0"
          style={{
            left: "33.33%",
            bottom: 40,
          }}
        >
          <a href={`/${story.content.video_link.cached_url}`}>
            {isMobileDevice ? (
              <VideoThumbnail
                video={story.content.video}
                alt={`homepage video`}
              />
            ) : (
              <Video video={story.content.video} autoPlay muted loop />
            )}
          </a>
        </div>
        {story.content.poetrybar && story.content.poetrybar.length && (
          <div
            className="fixed z-40 right-0 left-0 h-16"
            style={{ bottom: 40 }}
          >
            <PoetryBar lines={story.content.poetrybar.split("\n")} />
          </div>
        )}
      </main>
    </Layout>
  )
}

Home.propTypes = {
  story: PropTypes.shape({
    content: PropTypes.shape({
      collections: PropTypes.arrayOf(PropTypes.object).isRequired,
      video: PropTypes.object.isRequired,
      video_link: PropTypes.object.isRequired,
      poetrybar: PropTypes.string,
    }),
  }).isRequired,
}

Home.getInitialProps = async ({ query }) => {
  const cachedStory = getItem(key)
  let story

  if (cachedStory) {
    story = cachedStory
  } else {
    const res = await Storyblok.get("cdn/stories/home", {
      ...getStoryblokParams(query),
      resolve_relations: "collections",
    })
    story = res.data && res.data.story
  }

  return {
    story,
  }
}

export default Home
